header {
  margin-top: 4em;
  margin-bottom: 4em;

  .header-title {
    .illustration {
      width: 128px;
      margin-top: -128px;
      transform: translateY(100%) translateX(-120%);
    }
  }
}
