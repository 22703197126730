.footer {
    a {
        color: #F5F5F5;
    }

    .social-media {
        display: flex;
        flex-direction: row;

        figure:not(:last-child) {
            margin-right: 1em;
        }
    }

    .content {
        a {
            color: $link;
        }
    }
}