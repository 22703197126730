$body-background-color: #1f2233;
$navbar-background-color: transparent;
$navbar-item-hover-background-color: transparent;
$body-color: #f5f5f5;
$navbar-item-color: #f5f5f5;
$card-color: #f5f5f5;

$card-header-color: white;
$text-strong: white;
$text: #dcdcdc;

$link: #FF8080;
$link-hover: #ff7fbf;

$footer-background-color: lighten($body-background-color, 5%);;
$footer-color: white;

$card-background-color: lighten($body-background-color, 5%);

$tag-background-color: $body-background-color;
$tag-color: #F5F5F5;

$button-background-color: linear-gradient(90deg, rgba(172,77,131,1) 0%, rgba(106,86,176,1) 100%);

$content-blockquote-background-color: lighten($body-background-color, 10%);
$content-blockquote-border-left: 10px solid lighten($body-background-color, 5%);

$input-color: #F5F5F5;
$input-background-color: lighten($body-background-color, 5%);

$box-background-color: $body-background-color;
$box-color: #F5F5F5;
$box-radius: 10px;

$menu-item-hover-background-color: lighten($body-background-color, 5%);
$menu-item-hover-color: #F5F5F5;
$menu-label-color: #F5F5F5;

$pre-background: transparent;
$pre-padding: 1em;
$code-background: lighten($body-background-color, 10%);
