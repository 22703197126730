.move {
  &-left,
  &-right {
    border-width: 0 !important;

    &:hover {
      color: white !important;
    }
  }

  &-left {
    transition-duration: 0.3s;

    &:hover {
      transform: translateX(-1em);
    }
  }

  &-right {
    transition-duration: 0.3s;

    &:hover {
      transform: translateX(1em);
    }
  }
}
