@charset "utf-8";

@import "variables";
@import "article";
@import "header";
@import "card";
@import "buttons";
@import "footer";
@import "code";

@font-face {
  font-family: Viga;
  src: url('/assets/fonts/Viga-Regular.ttf');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/FiraSans-LightItalic.ttf') format('truetype');
}


@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/FiraSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/FiraSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/FiraSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/FiraSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/FiraSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/FiraSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/FiraSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/FiraSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/FiraSans-ExtraBold.ttf') format('truetype');
}


.is {
  &-horizontal-center {
    justify-content: center;
  }

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &-gradient {
    background: rgb(172, 77, 131);
    background: linear-gradient(90deg, #ff8080 0%, #ff7fbf 100%);
    border-width: 0 !important;
    color: #f5f5f5;

    &:hover {
      color: #f5f5f5 !important;
    }
  }

  &-huge {
    width: auto;
  }
}

.page-content {
  background-color: $body-background-color;
}

.navbar {
  font-family: "Viga";
}

.big {
  position: relative;
  min-width: 125%;
  left: -12.5%;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  transform: translateY(33%) translateX(-50%) !important;
  width: inherit !important;
  height: inherit !important;
}

$title-family: "Viga";

body {
  background: url("/assets/circle.svg") 95% 70% no-repeat fixed,
    url("/assets/cross.svg") 10% 75% no-repeat fixed,
    url("/assets/square.svg") 90% 15% no-repeat fixed,
    url("/assets/top_right.svg") no-repeat;
  overflow-x: hidden;
}

a {
  transition-duration: 0.3s;
}

.modal {
  &-content.image {
    width: 80%;
  }
}

.control.has-icons-left .icon.is-left {
  left: 20px !important;
}

@import "bulma/bulma.sass";
