.card {
    border-radius: 10px;

    &:hover {
        .title {
            color: #FF8080;
        }
    }

    &-content {
        a.title {
            transition-duration: .2s;
            &:hover {
                color: #ff8080;
            }
        }
    }
}