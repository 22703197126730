.article {
  transition-duration: 0.3s;

  &:hover {
    transform: translateY(-1em);
    transition-duration: 0.3s;
  }

  &-background.image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
  }

  &-header {
    border-radius: 10px;
    box-shadow: 4px 4px 15px -7px rgba(0, 0, 0, 0.28);
    position: relative;
  }
}

.header {
  &-category {
    position: absolute;
    right: 1em;
    top: 1em;
  }
}

.content {
  font-family: "Fira Sans";
  font-weight: 400;
  line-height: 30pt;

  strong {
    font-weight: 900;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Viga";
  }

  img {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  blockquote {
    border-radius: 6px;
  }

  .modalable {
    cursor: pointer;
  }

  .highlighter-rouge {
    margin-bottom: 2em;

    .highlight {
      border-radius: 10px;

      code {
        table {
          width: auto;
          td {
            padding: 0em;
          }
        }
      }
    }
  }
}
